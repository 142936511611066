.ant-popover-inner{
    background: transparent;
    box-shadow: none;
}

.ant-popover-inner-content{
    max-width: 260px;
    background: #333333;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    padding: 16px;
    border-radius: 16px;
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 20px;
}
.clear-history-popover .ant-popover-inner-content {
    background: white;
}
.clear-history-popover .ant-popover-buttons .ant-btn {
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: #281071;
}
.clear-history-popover .ant-popover-buttons .ant-btn-primary {
    background: #F0E2FE;
    color: #6D4EC9;
    border: none;
}
.clear-history-popover .ant-popover-arrow-content {
    background: white;
}
.clear-history-popover .ant-popover-message-title {
    color: #281071;
}

.ant-popover-arrow-content{
    width: 8px;
    height: 8px;
    background: rgba(0, 0, 0, 0.75);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
}

.ant-popover-arrow-content::before{
    width: 8px;
    height: 8px;
    background: rgba(0, 0, 0, 0.75);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
}
