.loading {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  top: 35%;
  font-size: 0;
}

.loading.reversed li {
  border: 2px solid white;
  animation: LOADINGREV 2s infinite;
}

.loading.reversed li:nth-child(1n) {
  animation-delay: 0s;
}

.loading.reversed li:nth-child(2n) {
  animation-delay: 0.2s;
}

.loading.reversed li:nth-child(3n) {
  animation-delay: 0.4s;
}

.loading li {
  position: absolute;
  left: 0;
  margin: 0;
  height: 8px;
  width: 8px;
  border: 1px solid #48E8CC;
  border-radius: 100%;
  transform: translateZ(0);
}

.loading li:nth-child(1n) {
  left: -7.5px;
  animation-delay: 0s;
}

.loading li:nth-child(2n) {
  left: 0;
  animation-delay: 0.2s;
}

.loading li:nth-child(3n) {
  left: 7.5px;
  animation-delay: 0.4s;
}

.grid-row:after {
  content: '';
  display: table;
  clear: both;
}

.grid-row .col {
  position: absolute;
  top: 0;
  left: 1px;
  bottom: 0;
  width: 50%;
}

.grid-row .col + .col {
  left: auto;
  right: 0;
}

@keyframes LOADINGREV {
  0% {
    transform: scale(0.5);
    background: #E9F2F7;
  }
  50% {
    transform: scale(1);
    background: #48E8CC;
  }
  100% {
    transform: scale(0.5);
    background: #E9F2F7;
  }
}
