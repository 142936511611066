.shake, .shake-little, .shake-slow, .shake-hard, .shake-horizontal, .shake-vertical, .shake-rotate, .shake-opacity, .shake-crazy, .shake-chunk {
  display: inline-block;
  transform-origin: center center
}

.shake-freeze, .shake-constant.shake-constant--hover:hover, .shake-trigger:hover .shake-constant.shake-constant--hover {
  animation-play-state: paused
}

.shake-freeze:hover, .shake-trigger:hover .shake-freeze, .shake:hover, .shake-trigger:hover .shake, .shake-little:hover, .shake-trigger:hover .shake-little, .shake-slow:hover, .shake-trigger:hover .shake-slow, .shake-hard:hover, .shake-trigger:hover .shake-hard, .shake-horizontal:hover, .shake-trigger:hover .shake-horizontal, .shake-vertical:hover, .shake-trigger:hover .shake-vertical, .shake-rotate:hover, .shake-trigger:hover .shake-rotate, .shake-opacity:hover, .shake-trigger:hover .shake-opacity, .shake-crazy:hover, .shake-trigger:hover .shake-crazy, .shake-chunk:hover, .shake-trigger:hover .shake-chunk {
  animation-play-state: running
}

@keyframes shake {
  2% {
    transform: translate(1.5px, 1.5px) rotate(-0.5deg)
  }
  4% {
    transform: translate(-1.5px, 1.5px) rotate(1.5deg)
  }
  6% {
    transform: translate(1.5px, 2.5px) rotate(-0.5deg)
  }
  8% {
    transform: translate(1.5px, -0.5px) rotate(0.5deg)
  }
  10% {
    transform: translate(2.5px, 0.5px) rotate(0.5deg)
  }
  12% {
    transform: translate(-1.5px, -0.5px) rotate(1.5deg)
  }
  14% {
    transform: translate(1.5px, 1.5px) rotate(1.5deg)
  }
  16% {
    transform: translate(0.5px, 0.5px) rotate(-0.5deg)
  }
  18% {
    transform: translate(-1.5px, -1.5px) rotate(1.5deg)
  }
  20% {
    transform: translate(1.5px, 2.5px) rotate(1.5deg)
  }
  22% {
    transform: translate(0.5px, 2.5px) rotate(1.5deg)
  }
  24% {
    transform: translate(2.5px, 1.5px) rotate(-0.5deg)
  }
  26% {
    transform: translate(0.5px, 1.5px) rotate(1.5deg)
  }
  28% {
    transform: translate(-0.5px, -1.5px) rotate(-0.5deg)
  }
  30% {
    transform: translate(-0.5px, 1.5px) rotate(-0.5deg)
  }
  32% {
    transform: translate(1.5px, 1.5px) rotate(-0.5deg)
  }
  34% {
    transform: translate(-1.5px, 0.5px) rotate(1.5deg)
  }
  36% {
    transform: translate(2.5px, 0.5px) rotate(-0.5deg)
  }
  38% {
    transform: translate(-0.5px, -0.5px) rotate(0.5deg)
  }
  40% {
    transform: translate(0.5px, -1.5px) rotate(-0.5deg)
  }
  42% {
    transform: translate(0.5px, 1.5px) rotate(1.5deg)
  }
  44% {
    transform: translate(2.5px, 0.5px) rotate(-0.5deg)
  }
  46% {
    transform: translate(2.5px, 0.5px) rotate(1.5deg)
  }
  48% {
    transform: translate(1.5px, 1.5px) rotate(-0.5deg)
  }
  50% {
    transform: translate(-0.5px, -0.5px) rotate(1.5deg)
  }
  52% {
    transform: translate(2.5px, -1.5px) rotate(0.5deg)
  }
  54% {
    transform: translate(0.5px, 2.5px) rotate(1.5deg)
  }
  56% {
    transform: translate(0.5px, 2.5px) rotate(-0.5deg)
  }
  58% {
    transform: translate(1.5px, 0.5px) rotate(-0.5deg)
  }
  60% {
    transform: translate(1.5px, 1.5px) rotate(-0.5deg)
  }
  62% {
    transform: translate(2.5px, -1.5px) rotate(1.5deg)
  }
  64% {
    transform: translate(2.5px, 1.5px) rotate(0.5deg)
  }
  66% {
    transform: translate(1.5px, 2.5px) rotate(1.5deg)
  }
  68% {
    transform: translate(-0.5px, 0.5px) rotate(0.5deg)
  }
  70% {
    transform: translate(-1.5px, 2.5px) rotate(1.5deg)
  }
  72% {
    transform: translate(1.5px, 2.5px) rotate(1.5deg)
  }
  74% {
    transform: translate(-0.5px, 2.5px) rotate(0.5deg)
  }
  76% {
    transform: translate(1.5px, 2.5px) rotate(0.5deg)
  }
  78% {
    transform: translate(2.5px, 0.5px) rotate(0.5deg)
  }
  80% {
    transform: translate(-1.5px, -0.5px) rotate(0.5deg)
  }
  82% {
    transform: translate(-0.5px, -1.5px) rotate(0.5deg)
  }
  84% {
    transform: translate(1.5px, 1.5px) rotate(-0.5deg)
  }
  86% {
    transform: translate(-0.5px, 2.5px) rotate(0.5deg)
  }
  88% {
    transform: translate(-1.5px, 2.5px) rotate(0.5deg)
  }
  90% {
    transform: translate(-0.5px, -0.5px) rotate(-0.5deg)
  }
  92% {
    transform: translate(2.5px, 1.5px) rotate(-0.5deg)
  }
  94% {
    transform: translate(1.5px, 2.5px) rotate(0.5deg)
  }
  96% {
    transform: translate(0.5px, 2.5px) rotate(-0.5deg)
  }
  98% {
    transform: translate(2.5px, 0.5px) rotate(1.5deg)
  }
  0%, 100% {
    transform: translate(0, 0) rotate(0)
  }
}

.shake:hover, .shake-trigger:hover .shake, .shake.shake-freeze, .shake.shake-constant {
  animation: shake 100ms ease-in-out infinite
}

@keyframes shake-little {
  2% {
    transform: translate(1px, 1px) rotate(0.5deg)
  }
  4% {
    transform: translate(1px, 1px) rotate(0.5deg)
  }
  6% {
    transform: translate(1px, 1px) rotate(0.5deg)
  }
  8% {
    transform: translate(1px, 1px) rotate(0.5deg)
  }
  10% {
    transform: translate(1px, 1px) rotate(0.5deg)
  }
  12% {
    transform: translate(1px, 0px) rotate(0.5deg)
  }
  14% {
    transform: translate(0px, 1px) rotate(0.5deg)
  }
  16% {
    transform: translate(1px, 0px) rotate(0.5deg)
  }
  18% {
    transform: translate(1px, 0px) rotate(0.5deg)
  }
  20% {
    transform: translate(0px, 0px) rotate(0.5deg)
  }
  22% {
    transform: translate(0px, 0px) rotate(0.5deg)
  }
  24% {
    transform: translate(0px, 1px) rotate(0.5deg)
  }
  26% {
    transform: translate(0px, 1px) rotate(0.5deg)
  }
  28% {
    transform: translate(0px, 1px) rotate(0.5deg)
  }
  30% {
    transform: translate(1px, 1px) rotate(0.5deg)
  }
  32% {
    transform: translate(0px, 1px) rotate(0.5deg)
  }
  34% {
    transform: translate(0px, 1px) rotate(0.5deg)
  }
  36% {
    transform: translate(1px, 0px) rotate(0.5deg)
  }
  38% {
    transform: translate(1px, 1px) rotate(0.5deg)
  }
  40% {
    transform: translate(1px, 0px) rotate(0.5deg)
  }
  42% {
    transform: translate(1px, 1px) rotate(0.5deg)
  }
  44% {
    transform: translate(0px, 0px) rotate(0.5deg)
  }
  46% {
    transform: translate(1px, 0px) rotate(0.5deg)
  }
  48% {
    transform: translate(1px, 1px) rotate(0.5deg)
  }
  50% {
    transform: translate(0px, 0px) rotate(0.5deg)
  }
  52% {
    transform: translate(1px, 1px) rotate(0.5deg)
  }
  54% {
    transform: translate(0px, 0px) rotate(0.5deg)
  }
  56% {
    transform: translate(0px, 1px) rotate(0.5deg)
  }
  58% {
    transform: translate(1px, 1px) rotate(0.5deg)
  }
  60% {
    transform: translate(0px, 0px) rotate(0.5deg)
  }
  62% {
    transform: translate(0px, 0px) rotate(0.5deg)
  }
  64% {
    transform: translate(0px, 0px) rotate(0.5deg)
  }
  66% {
    transform: translate(0px, 1px) rotate(0.5deg)
  }
  68% {
    transform: translate(1px, 1px) rotate(0.5deg)
  }
  70% {
    transform: translate(0px, 0px) rotate(0.5deg)
  }
  72% {
    transform: translate(0px, 0px) rotate(0.5deg)
  }
  74% {
    transform: translate(0px, 0px) rotate(0.5deg)
  }
  76% {
    transform: translate(0px, 1px) rotate(0.5deg)
  }
  78% {
    transform: translate(0px, 0px) rotate(0.5deg)
  }
  80% {
    transform: translate(1px, 1px) rotate(0.5deg)
  }
  82% {
    transform: translate(0px, 0px) rotate(0.5deg)
  }
  84% {
    transform: translate(1px, 0px) rotate(0.5deg)
  }
  86% {
    transform: translate(1px, 1px) rotate(0.5deg)
  }
  88% {
    transform: translate(1px, 1px) rotate(0.5deg)
  }
  90% {
    transform: translate(1px, 1px) rotate(0.5deg)
  }
  92% {
    transform: translate(1px, 1px) rotate(0.5deg)
  }
  94% {
    transform: translate(0px, 1px) rotate(0.5deg)
  }
  96% {
    transform: translate(0px, 1px) rotate(0.5deg)
  }
  98% {
    transform: translate(0px, 1px) rotate(0.5deg)
  }
  0%, 100% {
    transform: translate(0, 0) rotate(0)
  }
}

.shake-little:hover, .shake-trigger:hover .shake-little, .shake-little.shake-freeze, .shake-little.shake-constant {
  animation: shake-little 100ms ease-in-out infinite
}

@keyframes shake-slow {
  2% {
    transform: translate(2px, -9px) rotate(2.5deg)
  }
  4% {
    transform: translate(-4px, 5px) rotate(-2.5deg)
  }
  6% {
    transform: translate(-8px, 8px) rotate(3.5deg)
  }
  8% {
    transform: translate(-7px, 4px) rotate(-1.5deg)
  }
  10% {
    transform: translate(7px, 0px) rotate(1.5deg)
  }
  12% {
    transform: translate(3px, 8px) rotate(-0.5deg)
  }
  14% {
    transform: translate(4px, 4px) rotate(0.5deg)
  }
  16% {
    transform: translate(-4px, -4px) rotate(3.5deg)
  }
  18% {
    transform: translate(-8px, -7px) rotate(3.5deg)
  }
  20% {
    transform: translate(-9px, 8px) rotate(2.5deg)
  }
  22% {
    transform: translate(-9px, -5px) rotate(-2.5deg)
  }
  24% {
    transform: translate(4px, -7px) rotate(-2.5deg)
  }
  26% {
    transform: translate(-1px, 3px) rotate(1.5deg)
  }
  28% {
    transform: translate(-3px, -7px) rotate(3.5deg)
  }
  30% {
    transform: translate(6px, -9px) rotate(2.5deg)
  }
  32% {
    transform: translate(8px, -5px) rotate(-2.5deg)
  }
  34% {
    transform: translate(7px, 8px) rotate(1.5deg)
  }
  36% {
    transform: translate(2px, 5px) rotate(-2.5deg)
  }
  38% {
    transform: translate(-6px, 0px) rotate(2.5deg)
  }
  40% {
    transform: translate(9px, 7px) rotate(-2.5deg)
  }
  42% {
    transform: translate(-2px, -2px) rotate(-0.5deg)
  }
  44% {
    transform: translate(0px, -6px) rotate(-2.5deg)
  }
  46% {
    transform: translate(-5px, 2px) rotate(1.5deg)
  }
  48% {
    transform: translate(-8px, -7px) rotate(3.5deg)
  }
  50% {
    transform: translate(-5px, -6px) rotate(-2.5deg)
  }
  52% {
    transform: translate(8px, 1px) rotate(-2.5deg)
  }
  54% {
    transform: translate(-1px, -1px) rotate(-2.5deg)
  }
  56% {
    transform: translate(5px, -1px) rotate(2.5deg)
  }
  58% {
    transform: translate(-6px, -8px) rotate(-2.5deg)
  }
  60% {
    transform: translate(5px, 5px) rotate(3.5deg)
  }
  62% {
    transform: translate(-4px, -2px) rotate(1.5deg)
  }
  64% {
    transform: translate(-5px, 7px) rotate(3.5deg)
  }
  66% {
    transform: translate(7px, 4px) rotate(0.5deg)
  }
  68% {
    transform: translate(-5px, -2px) rotate(-2.5deg)
  }
  70% {
    transform: translate(1px, 3px) rotate(-1.5deg)
  }
  72% {
    transform: translate(-6px, 0px) rotate(2.5deg)
  }
  74% {
    transform: translate(1px, 9px) rotate(2.5deg)
  }
  76% {
    transform: translate(10px, -5px) rotate(-2.5deg)
  }
  78% {
    transform: translate(-5px, 4px) rotate(3.5deg)
  }
  80% {
    transform: translate(-6px, 1px) rotate(0.5deg)
  }
  82% {
    transform: translate(9px, 10px) rotate(2.5deg)
  }
  84% {
    transform: translate(-1px, 5px) rotate(-1.5deg)
  }
  86% {
    transform: translate(4px, 1px) rotate(2.5deg)
  }
  88% {
    transform: translate(-5px, -7px) rotate(1.5deg)
  }
  90% {
    transform: translate(-8px, -2px) rotate(0.5deg)
  }
  92% {
    transform: translate(10px, -9px) rotate(-0.5deg)
  }
  94% {
    transform: translate(7px, 6px) rotate(-0.5deg)
  }
  96% {
    transform: translate(6px, 1px) rotate(-2.5deg)
  }
  98% {
    transform: translate(5px, 0px) rotate(1.5deg)
  }
  0%, 100% {
    transform: translate(0, 0) rotate(0)
  }
}

.shake-slow:hover, .shake-trigger:hover .shake-slow, .shake-slow.shake-freeze, .shake-slow.shake-constant {
  animation: shake-slow 5s ease-in-out infinite
}

@keyframes shake-hard {
  2% {
    transform: translate(2px, -5px) rotate(2.5deg)
  }
  4% {
    transform: translate(4px, 6px) rotate(-1.5deg)
  }
  6% {
    transform: translate(-5px, 3px) rotate(-2.5deg)
  }
  8% {
    transform: translate(-8px, 7px) rotate(3.5deg)
  }
  10% {
    transform: translate(-2px, -8px) rotate(3.5deg)
  }
  12% {
    transform: translate(-9px, -1px) rotate(1.5deg)
  }
  14% {
    transform: translate(1px, -8px) rotate(-0.5deg)
  }
  16% {
    transform: translate(-3px, 10px) rotate(-1.5deg)
  }
  18% {
    transform: translate(9px, -4px) rotate(0.5deg)
  }
  20% {
    transform: translate(4px, 8px) rotate(2.5deg)
  }
  22% {
    transform: translate(3px, 2px) rotate(-0.5deg)
  }
  24% {
    transform: translate(-5px, 6px) rotate(2.5deg)
  }
  26% {
    transform: translate(-7px, -6px) rotate(0.5deg)
  }
  28% {
    transform: translate(3px, 0px) rotate(2.5deg)
  }
  30% {
    transform: translate(8px, -8px) rotate(2.5deg)
  }
  32% {
    transform: translate(-9px, -8px) rotate(2.5deg)
  }
  34% {
    transform: translate(-9px, 3px) rotate(2.5deg)
  }
  36% {
    transform: translate(-2px, 7px) rotate(2.5deg)
  }
  38% {
    transform: translate(8px, 7px) rotate(-1.5deg)
  }
  40% {
    transform: translate(4px, 0px) rotate(-1.5deg)
  }
  42% {
    transform: translate(-4px, -9px) rotate(-0.5deg)
  }
  44% {
    transform: translate(0px, -4px) rotate(2.5deg)
  }
  46% {
    transform: translate(4px, 2px) rotate(2.5deg)
  }
  48% {
    transform: translate(10px, -9px) rotate(2.5deg)
  }
  50% {
    transform: translate(3px, -6px) rotate(2.5deg)
  }
  52% {
    transform: translate(1px, 6px) rotate(0.5deg)
  }
  54% {
    transform: translate(3px, -1px) rotate(-1.5deg)
  }
  56% {
    transform: translate(-1px, -9px) rotate(0.5deg)
  }
  58% {
    transform: translate(7px, -4px) rotate(-0.5deg)
  }
  60% {
    transform: translate(2px, 0px) rotate(2.5deg)
  }
  62% {
    transform: translate(-5px, 3px) rotate(0.5deg)
  }
  64% {
    transform: translate(6px, -8px) rotate(3.5deg)
  }
  66% {
    transform: translate(1px, -3px) rotate(2.5deg)
  }
  68% {
    transform: translate(10px, 1px) rotate(1.5deg)
  }
  70% {
    transform: translate(0px, 7px) rotate(-0.5deg)
  }
  72% {
    transform: translate(-9px, 6px) rotate(3.5deg)
  }
  74% {
    transform: translate(8px, 0px) rotate(-0.5deg)
  }
  76% {
    transform: translate(0px, 5px) rotate(0.5deg)
  }
  78% {
    transform: translate(6px, 6px) rotate(-0.5deg)
  }
  80% {
    transform: translate(4px, 3px) rotate(-2.5deg)
  }
  82% {
    transform: translate(8px, -2px) rotate(3.5deg)
  }
  84% {
    transform: translate(0px, -8px) rotate(1.5deg)
  }
  86% {
    transform: translate(-2px, -8px) rotate(2.5deg)
  }
  88% {
    transform: translate(10px, -7px) rotate(2.5deg)
  }
  90% {
    transform: translate(2px, 10px) rotate(-0.5deg)
  }
  92% {
    transform: translate(-9px, 4px) rotate(2.5deg)
  }
  94% {
    transform: translate(-3px, 1px) rotate(1.5deg)
  }
  96% {
    transform: translate(-2px, -1px) rotate(0.5deg)
  }
  98% {
    transform: translate(7px, -9px) rotate(3.5deg)
  }
  0%, 100% {
    transform: translate(0, 0) rotate(0)
  }
}

.shake-hard:hover, .shake-trigger:hover .shake-hard, .shake-hard.shake-freeze, .shake-hard.shake-constant {
  animation: shake-hard 100ms ease-in-out infinite
}

@keyframes shake-horizontal {
  2% {
    transform: translate(4px, 0) rotate(0)
  }
  4% {
    transform: translate(7px, 0) rotate(0)
  }
  6% {
    transform: translate(-2px, 0) rotate(0)
  }
  8% {
    transform: translate(-7px, 0) rotate(0)
  }
  10% {
    transform: translate(-1px, 0) rotate(0)
  }
  12% {
    transform: translate(-2px, 0) rotate(0)
  }
  14% {
    transform: translate(1px, 0) rotate(0)
  }
  16% {
    transform: translate(-8px, 0) rotate(0)
  }
  18% {
    transform: translate(-5px, 0) rotate(0)
  }
  20% {
    transform: translate(9px, 0) rotate(0)
  }
  22% {
    transform: translate(-5px, 0) rotate(0)
  }
  24% {
    transform: translate(6px, 0) rotate(0)
  }
  26% {
    transform: translate(2px, 0) rotate(0)
  }
  28% {
    transform: translate(2px, 0) rotate(0)
  }
  30% {
    transform: translate(-5px, 0) rotate(0)
  }
  32% {
    transform: translate(-7px, 0) rotate(0)
  }
  34% {
    transform: translate(10px, 0) rotate(0)
  }
  36% {
    transform: translate(1px, 0) rotate(0)
  }
  38% {
    transform: translate(-2px, 0) rotate(0)
  }
  40% {
    transform: translate(4px, 0) rotate(0)
  }
  42% {
    transform: translate(-8px, 0) rotate(0)
  }
  44% {
    transform: translate(5px, 0) rotate(0)
  }
  46% {
    transform: translate(9px, 0) rotate(0)
  }
  48% {
    transform: translate(6px, 0) rotate(0)
  }
  50% {
    transform: translate(-9px, 0) rotate(0)
  }
  52% {
    transform: translate(7px, 0) rotate(0)
  }
  54% {
    transform: translate(-9px, 0) rotate(0)
  }
  56% {
    transform: translate(-7px, 0) rotate(0)
  }
  58% {
    transform: translate(-8px, 0) rotate(0)
  }
  60% {
    transform: translate(3px, 0) rotate(0)
  }
  62% {
    transform: translate(-7px, 0) rotate(0)
  }
  64% {
    transform: translate(6px, 0) rotate(0)
  }
  66% {
    transform: translate(-4px, 0) rotate(0)
  }
  68% {
    transform: translate(-2px, 0) rotate(0)
  }
  70% {
    transform: translate(6px, 0) rotate(0)
  }
  72% {
    transform: translate(-9px, 0) rotate(0)
  }
  74% {
    transform: translate(7px, 0) rotate(0)
  }
  76% {
    transform: translate(2px, 0) rotate(0)
  }
  78% {
    transform: translate(-8px, 0) rotate(0)
  }
  80% {
    transform: translate(2px, 0) rotate(0)
  }
  82% {
    transform: translate(2px, 0) rotate(0)
  }
  84% {
    transform: translate(-4px, 0) rotate(0)
  }
  86% {
    transform: translate(-7px, 0) rotate(0)
  }
  88% {
    transform: translate(4px, 0) rotate(0)
  }
  90% {
    transform: translate(-6px, 0) rotate(0)
  }
  92% {
    transform: translate(-8px, 0) rotate(0)
  }
  94% {
    transform: translate(-3px, 0) rotate(0)
  }
  96% {
    transform: translate(4px, 0) rotate(0)
  }
  98% {
    transform: translate(-8px, 0) rotate(0)
  }
  0%, 100% {
    transform: translate(0, 0) rotate(0)
  }
}

.shake-horizontal:hover, .shake-trigger:hover .shake-horizontal, .shake-horizontal.shake-freeze, .shake-horizontal.shake-constant {
  animation: shake-horizontal 100ms ease-in-out infinite
}

@keyframes shake-vertical {
  2% {
    transform: translate(0, 6px) rotate(0)
  }
  4% {
    transform: translate(0, -1px) rotate(0)
  }
  6% {
    transform: translate(0, -7px) rotate(0)
  }
  8% {
    transform: translate(0, -1px) rotate(0)
  }
  10% {
    transform: translate(0, 9px) rotate(0)
  }
  12% {
    transform: translate(0, 1px) rotate(0)
  }
  14% {
    transform: translate(0, 3px) rotate(0)
  }
  16% {
    transform: translate(0, 6px) rotate(0)
  }
  18% {
    transform: translate(0, 0px) rotate(0)
  }
  20% {
    transform: translate(0, 2px) rotate(0)
  }
  22% {
    transform: translate(0, 1px) rotate(0)
  }
  24% {
    transform: translate(0, 3px) rotate(0)
  }
  26% {
    transform: translate(0, 4px) rotate(0)
  }
  28% {
    transform: translate(0, 0px) rotate(0)
  }
  30% {
    transform: translate(0, -8px) rotate(0)
  }
  32% {
    transform: translate(0, 6px) rotate(0)
  }
  34% {
    transform: translate(0, 6px) rotate(0)
  }
  36% {
    transform: translate(0, -4px) rotate(0)
  }
  38% {
    transform: translate(0, 2px) rotate(0)
  }
  40% {
    transform: translate(0, -8px) rotate(0)
  }
  42% {
    transform: translate(0, -9px) rotate(0)
  }
  44% {
    transform: translate(0, -3px) rotate(0)
  }
  46% {
    transform: translate(0, 0px) rotate(0)
  }
  48% {
    transform: translate(0, -7px) rotate(0)
  }
  50% {
    transform: translate(0, 0px) rotate(0)
  }
  52% {
    transform: translate(0, 3px) rotate(0)
  }
  54% {
    transform: translate(0, -4px) rotate(0)
  }
  56% {
    transform: translate(0, 3px) rotate(0)
  }
  58% {
    transform: translate(0, -9px) rotate(0)
  }
  60% {
    transform: translate(0, 9px) rotate(0)
  }
  62% {
    transform: translate(0, -6px) rotate(0)
  }
  64% {
    transform: translate(0, 0px) rotate(0)
  }
  66% {
    transform: translate(0, -4px) rotate(0)
  }
  68% {
    transform: translate(0, 1px) rotate(0)
  }
  70% {
    transform: translate(0, 5px) rotate(0)
  }
  72% {
    transform: translate(0, 0px) rotate(0)
  }
  74% {
    transform: translate(0, -6px) rotate(0)
  }
  76% {
    transform: translate(0, -3px) rotate(0)
  }
  78% {
    transform: translate(0, 3px) rotate(0)
  }
  80% {
    transform: translate(0, 6px) rotate(0)
  }
  82% {
    transform: translate(0, 2px) rotate(0)
  }
  84% {
    transform: translate(0, -3px) rotate(0)
  }
  86% {
    transform: translate(0, 1px) rotate(0)
  }
  88% {
    transform: translate(0, 1px) rotate(0)
  }
  90% {
    transform: translate(0, 10px) rotate(0)
  }
  92% {
    transform: translate(0, -2px) rotate(0)
  }
  94% {
    transform: translate(0, -2px) rotate(0)
  }
  96% {
    transform: translate(0, -6px) rotate(0)
  }
  98% {
    transform: translate(0, -9px) rotate(0)
  }
  0%, 100% {
    transform: translate(0, 0) rotate(0)
  }
}

.shake-vertical:hover, .shake-trigger:hover .shake-vertical, .shake-vertical.shake-freeze, .shake-vertical.shake-constant {
  animation: shake-vertical 100ms ease-in-out infinite
}

@keyframes shake-rotate {
  2% {
    transform: translate(0, 0) rotate(3.5deg)
  }
  4% {
    transform: translate(0, 0) rotate(4.5deg)
  }
  6% {
    transform: translate(0, 0) rotate(1.5deg)
  }
  8% {
    transform: translate(0, 0) rotate(2.5deg)
  }
  10% {
    transform: translate(0, 0) rotate(3.5deg)
  }
  12% {
    transform: translate(0, 0) rotate(0.5deg)
  }
  14% {
    transform: translate(0, 0) rotate(-5.5deg)
  }
  16% {
    transform: translate(0, 0) rotate(-1.5deg)
  }
  18% {
    transform: translate(0, 0) rotate(1.5deg)
  }
  20% {
    transform: translate(0, 0) rotate(6.5deg)
  }
  22% {
    transform: translate(0, 0) rotate(3.5deg)
  }
  24% {
    transform: translate(0, 0) rotate(6.5deg)
  }
  26% {
    transform: translate(0, 0) rotate(-0.5deg)
  }
  28% {
    transform: translate(0, 0) rotate(7.5deg)
  }
  30% {
    transform: translate(0, 0) rotate(6.5deg)
  }
  32% {
    transform: translate(0, 0) rotate(-3.5deg)
  }
  34% {
    transform: translate(0, 0) rotate(-1.5deg)
  }
  36% {
    transform: translate(0, 0) rotate(3.5deg)
  }
  38% {
    transform: translate(0, 0) rotate(7.5deg)
  }
  40% {
    transform: translate(0, 0) rotate(-0.5deg)
  }
  42% {
    transform: translate(0, 0) rotate(3.5deg)
  }
  44% {
    transform: translate(0, 0) rotate(7.5deg)
  }
  46% {
    transform: translate(0, 0) rotate(7.5deg)
  }
  48% {
    transform: translate(0, 0) rotate(3.5deg)
  }
  50% {
    transform: translate(0, 0) rotate(0.5deg)
  }
  52% {
    transform: translate(0, 0) rotate(2.5deg)
  }
  54% {
    transform: translate(0, 0) rotate(5.5deg)
  }
  56% {
    transform: translate(0, 0) rotate(2.5deg)
  }
  58% {
    transform: translate(0, 0) rotate(-4.5deg)
  }
  60% {
    transform: translate(0, 0) rotate(-4.5deg)
  }
  62% {
    transform: translate(0, 0) rotate(7.5deg)
  }
  64% {
    transform: translate(0, 0) rotate(0.5deg)
  }
  66% {
    transform: translate(0, 0) rotate(2.5deg)
  }
  68% {
    transform: translate(0, 0) rotate(2.5deg)
  }
  70% {
    transform: translate(0, 0) rotate(5.5deg)
  }
  72% {
    transform: translate(0, 0) rotate(5.5deg)
  }
  74% {
    transform: translate(0, 0) rotate(-2.5deg)
  }
  76% {
    transform: translate(0, 0) rotate(7.5deg)
  }
  78% {
    transform: translate(0, 0) rotate(2.5deg)
  }
  80% {
    transform: translate(0, 0) rotate(-6.5deg)
  }
  82% {
    transform: translate(0, 0) rotate(-0.5deg)
  }
  84% {
    transform: translate(0, 0) rotate(2.5deg)
  }
  86% {
    transform: translate(0, 0) rotate(5.5deg)
  }
  88% {
    transform: translate(0, 0) rotate(0.5deg)
  }
  90% {
    transform: translate(0, 0) rotate(-0.5deg)
  }
  92% {
    transform: translate(0, 0) rotate(-1.5deg)
  }
  94% {
    transform: translate(0, 0) rotate(-0.5deg)
  }
  96% {
    transform: translate(0, 0) rotate(0.5deg)
  }
  98% {
    transform: translate(0, 0) rotate(-4.5deg)
  }
  0%, 100% {
    transform: translate(0, 0) rotate(0)
  }
}

.shake-rotate:hover, .shake-trigger:hover .shake-rotate, .shake-rotate.shake-freeze, .shake-rotate.shake-constant {
  animation: shake-rotate 100ms ease-in-out infinite
}

@keyframes shake-opacity {
  10% {
    transform: translate(-4px, 4px) rotate(-1.5deg);
    opacity: 0.25
  }
  20% {
    transform: translate(-1px, 2px) rotate(0.5deg);
    opacity: 1
  }
  30% {
    transform: translate(2px, -4px) rotate(-1.5deg);
    opacity: 0.03
  }
  40% {
    transform: translate(-1px, -2px) rotate(1.5deg);
    opacity: 0.55
  }
  50% {
    transform: translate(5px, -4px) rotate(1.5deg);
    opacity: 0.09
  }
  60% {
    transform: translate(-1px, 1px) rotate(-1.5deg);
    opacity: 0.97
  }
  70% {
    transform: translate(4px, 1px) rotate(0.5deg);
    opacity: 0.96
  }
  80% {
    transform: translate(3px, 2px) rotate(2.5deg);
    opacity: 0.83
  }
  90% {
    transform: translate(-2px, -4px) rotate(-1.5deg);
    opacity: 0.09
  }
  0%, 100% {
    transform: translate(0, 0) rotate(0)
  }
}

.shake-opacity:hover, .shake-trigger:hover .shake-opacity, .shake-opacity.shake-freeze, .shake-opacity.shake-constant {
  animation: shake-opacity 0.5s ease-in-out infinite
}

@keyframes shake-crazy {
  10% {
    transform: translate(-10px, -19px) rotate(6deg);
    opacity: 0.47
  }
  20% {
    transform: translate(-11px, 10px) rotate(5deg);
    opacity: 0.82
  }
  30% {
    transform: translate(17px, -3px) rotate(-9deg);
    opacity: 0.34
  }
  40% {
    transform: translate(11px, 19px) rotate(5deg);
    opacity: 0.4
  }
  50% {
    transform: translate(-11px, 13px) rotate(1deg);
    opacity: 0.97
  }
  60% {
    transform: translate(17px, -16px) rotate(7deg);
    opacity: 0.24
  }
  70% {
    transform: translate(-10px, -12px) rotate(-6deg);
    opacity: 0.56
  }
  80% {
    transform: translate(13px, -19px) rotate(-4deg);
    opacity: 0.96
  }
  90% {
    transform: translate(-18px, -11px) rotate(3deg);
    opacity: 0.49
  }
  0%, 100% {
    transform: translate(0, 0) rotate(0)
  }
}

.shake-crazy:hover, .shake-trigger:hover .shake-crazy, .shake-crazy.shake-freeze, .shake-crazy.shake-constant {
  animation: shake-crazy 100ms ease-in-out infinite
}

@keyframes shake-chunk {
  2% {
    transform: translate(5px, 2px) rotate(-12deg)
  }
  4% {
    transform: translate(-6px, 3px) rotate(1deg)
  }
  6% {
    transform: translate(3px, 6px) rotate(14deg)
  }
  8% {
    transform: translate(1px, 8px) rotate(1deg)
  }
  10% {
    transform: translate(-5px, 10px) rotate(0deg)
  }
  12% {
    transform: translate(-11px, 2px) rotate(7deg)
  }
  14% {
    transform: translate(4px, 15px) rotate(11deg)
  }
  16% {
    transform: translate(4px, -8px) rotate(15deg)
  }
  18% {
    transform: translate(-5px, 10px) rotate(1deg)
  }
  20% {
    transform: translate(-1px, 3px) rotate(15deg)
  }
  22% {
    transform: translate(-8px, 5px) rotate(-6deg)
  }
  24% {
    transform: translate(-1px, -9px) rotate(8deg)
  }
  26% {
    transform: translate(9px, 11px) rotate(-13deg)
  }
  28% {
    transform: translate(-7px, 4px) rotate(9deg)
  }
  30% {
    transform: translate(8px, 14px) rotate(9deg)
  }
  32% {
    transform: translate(-4px, 11px) rotate(-11deg)
  }
  34% {
    transform: translate(14px, 11px) rotate(-8deg)
  }
  36% {
    transform: translate(-13px, -8px) rotate(13deg)
  }
  38% {
    transform: translate(-12px, 1px) rotate(-13deg)
  }
  0%, 40%, 100% {
    transform: translate(0, 0) rotate(0)
  }
}

.shake-chunk, .shake-trigger:hover .shake-chunk, .shake-chunk.shake-freeze, .shake-chunk.shake-constant {
  animation: shake-chunk 4s ease-in-out infinite;
}

.red {
  width: 60%;
  padding-top: 100%;
  margin: 20px 20%;
  position: relative;
}

.red > span {
  width: 100%;
  height: 100%;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  position: absolute;
}

.red > button {
  position: absolute;
  top: 38%;
  left: 30%;
  font-size: 14px;
  width: 40%;
  height: 24%;
  border-radius: 100%;
  background: #fdc339;
  color: #fff;
}

.red-jg {
  display: none;
  position: absolute;
  top: 40%;
  text-align: center;
  padding: 10px;
}

.red-jg > h1 {
  font-size: 20px;
  color: #ffc000;
  line-height: 40px;
}

.red-jg > h5 {
  color: #fff;
}


/*按钮*/
.t-btn {
  padding: 10px;
}

.t-btn > button {
  width: 100%;
  background: #ff4242;
  border-radius: 5px;
  color: #fff;
  line-height: 40px;
  font-size: 14px;
}